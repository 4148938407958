import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationImage } from "../../../../utils/api";
import {
  ImageValidation,
  ProductNarrationChangeComa,
  Select2Data,
} from "../../../../utils/common";
import { CancelButton } from "../../../common/Button";
import { Context } from "../../../../utils/context";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./ProductEdit.css";
// import MultiVariant from "../MultiVariant/MultiVariant";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
// import SuccessModal from "../../../../../../../../../CommonForAll/SuccessModal/SuccessModal";

import Cookies from "js-cookie";
import Successfull_Modal from "../../../common/Successfull_Modal/Successfull_Modal";
import {
  Attributes,
  SubAttributes,
} from "../../../../utils/apis/master/Master";
import {
  SelectedAttributeDelete,
  SelectedVariantGet,
  SelectedVariantPost,
} from "../../../../utils/apis/product/Product";
// import {
//   RemoveAll,
//   VariantIdDataGet,
//   VariantIdDataPost,
//   multipleVariantGet,
// } from "../../../../../../../../../../utils/apis/seller/Product";

// import {
//   GST,
//   PlatformCharges,
//   Unit,
//   ProductInfoIcon,
// } from "../../../../../../../../../../utils/apis/master/Master";
// import { SelectHsnData } from "../../../../../../../../../../utils/common";
// import { hsnCodes } from "../../../../../../../../../../utils/apis/seller/masters";
function Step2({ handleNext, handlePrevious, ...props }) {
  const { getData, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      attribute_data: [
        { attribute_id: "", sub_attribute_id: [] },
        // { attribute_id: "", sub_attribute_id: [] },
      ],
    },
  });

  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  // const [subAtributes, setSubAtributes] = useState([]);
  // const [attributeCount, setAttributeCount] = useState(1);
  const [productInfoIcon, setProductInfoIcon] = useState([]);
  const { append, fields, remove } = useFieldArray({
    control,
    name: "attribute_data",
  });

  const [tableData, setTableData] = useState([]);
  const getSelectedVariant = async () => {
    if (props.product_id) {
      const res = await SelectedVariantGet(props.product_id);
      if (res?.success) {
        if (res.data.p_as.length) {
          setSubmitStatus(true);
        }
        setTableData(res.data);
      }
    }
  };

  useEffect(() => {
    if (props.product_id) {
      getSelectedVariant();
    }
  }, [props.product_id]);

  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const getDataAll = async () => {
    try {
      const response = await Attributes();
      if (response?.success) {
        setCategory(await Select2Data(response?.data, "attribute_id"));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const getSubAttributes = async (id) => {
  //   try {
  //     const response = await SubAttributes(id);
  //     setSubAtributes(await Select2Data(response?.data, "sub_attribute_id"));
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    getDataAll();
  }, []);

  // const handleNext = () => {
  //   setRedirectToNext(true);
  // };

  const onSubmit = async (d) => {
   
    var data = {
      product_id: props.product_id,
      type: 1,
      attribute_one_id: "",
      attribute_two_id: "",
      attribute_three_id: "",
      sub_one: [],
      sub_two: [],
      sub_three: [],
    };
    let total_count = 1;
    getValues("attribute_data")?.forEach((value, index) => {
      if (index === 0) {
        data.attribute_one_id = value.attribute_id.value;
        data.sub_one = JSON.stringify(selectedOptions1);
        data.type = 1;
        // total_count *= selectedOptions1.length;
      } else if (index === 1) {
        data.attribute_two_id = value.attribute_id.value;
        data.sub_two = JSON.stringify(selectedOptions2);
        data.type = 2;
        // total_count *= selectedOptions2.length;
      }
      // else if (index === 2) {
      //   data.attribute_three_id = value.attribute_id.value;
      //   data.sub_three = JSON.stringify(selectedOptions3);
      // data.type = 3;
      //   // total_count *= selectedOptions3.length;
      // }
      else {
      }
    });

   

    if (total_count <= 16) {
      setError("");
      const res = await SelectedVariantPost(data);

      if (res?.success) {
        setSubmitStatus(true);
        getSelectedVariant();
      }
    } else {
      setError("Please Select Variants less than 16 or equal");
    }
  };

  const deleteAttribute = async (type, id) => {
 
    const res = await SelectedAttributeDelete(type, id);
    if (res?.success) {
      getSelectedVariant();
    }
  };

  const [selectedOptions1, setSelectedOption1] = useState([]);
  const [selectedOptions2, setSelectedOption2] = useState([]);
  const [selectedOptions3, setSelectedOption3] = useState([]);

  const handleFields = async (selectedOption, index) => {
    setError("");
    if (index == 0) {
      const removedOption = selectedOptions1.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions1.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption1(selectedOption);
    } else if (index == 1) {
      const removedOption = selectedOptions2.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions2.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption2(selectedOption);
    } else if (index == 2) {
      const removedOption = selectedOptions3.find(
        (option) => !selectedOption.includes(option)
      );
      if (removedOption) {
        const removedIndex = selectedOptions3.indexOf(removedOption);

        // remove(removedIndex);
      }
      setSelectedOption3(selectedOption);
    }
  };

  return (
    <>
      <fieldset>
        <section className="single-varient-sec">
          <div className="sec-div ">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-md-6">
                <div className="varient-firsttt">
                  {fields.map((item, index) => (
                    <div key={item.id}>
                      <Form>
                        <Form.Group
                          className="mb-3"
                          // controlId={`attribute-${index + 1}`}
                        >
                          <Form.Label>
                            Attribute {index + 1}
                            <span className="mandatory-star"> *</span>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-title">
                                  Attribute {index + 1}
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-info"
                                className="ms-3"
                              />
                            </OverlayTrigger>
                          </Form.Label>

                          <Controller
                            // name={`attribute_id_${index + 1}`}
                            name={`attribute_data[${index}].attribute_id`}
                            {...register(
                              `attribute_data[${index}].attribute_id`,
                              {
                                required: "Select Attribute",
                              }
                            )}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.attribute_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={category}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  // getSubAttributes(selectedOption.value, index);
                                  setValue(
                                    `attribute_data[${index}].attribute_id`,
                                    selectedOption
                                  );
                                }}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {
                              getValues(`attribute_data[${index}].attribute_id`)
                                ?.label
                            }{" "}
                            {index + 1}
                            <span className="mandatory-star"> *</span>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-title">
                                  {productInfoIcon?.sub_attribute} {index + 1}
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-info"
                                className="ms-3"
                              />
                            </OverlayTrigger>
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name={`attribute_data[${index}].sub_attribute_id`}
                            placeholder="Attributes"
                            // accept={InputeType[`${getValues("input_type_id")}`]?.value}
                            className={classNames("", {
                              "is-invalid": errors?.s_s_a_id,
                            })}
                            {...register(
                              `attribute_data[${index}].sub_attribute_id`,
                              {
                                // pattern: InputeType[`${getValues("input_type_id")}`],
                                // required: "Name is required",
                              }
                            )}
                            onChange={(e) => {
                              // var valuesArray = e.target.value
                              //   .split(",")
                              //   .filter(function (item) {
                              //     return item.trim() !== "";
                              //   });
                              // setTimeout(() => {
                              var valuesArray = ProductNarrationChangeComa(e)
                                .split(",")
                                .filter(function (item) {
                                  return item.trim() !== "";
                                })
                                .filter(function (item, index, self) {
                                  return self.indexOf(item) === index;
                                });
                              handleFields(valuesArray, index);
                              setValue(
                                `attribute_data[${index}].sub_attribute_id`,
                                ProductNarrationChangeComa(e)
                              );
                              // }, 2000);
                            }}
                          />

                          {/* <Controller
                          name={`attribute_data[${index}].sub_attribute_id`} // name of the field
                          {...register(
                            `attribute_data[${index}].sub_attribute_id`,
                            {
                              required: "Select Sub Attribute",
                            }
                          )}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.sub_attribute_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={subAtributes}
                              isMulti
                            />
                          )}
                        /> */}
                        </Form.Group>

                        {index > 0 && (
                          <Button
                            className="remove-btn-class"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </Button>
                        )}
                      </Form>
                    </div>
                  ))}
                  <div className="text-end">
                    {fields.length < 2 && (
                      <Button
                        className="add-new-attribute-class"
                        onClick={() =>
                          append({ attribute_id: "", sub_attribute_id: [] })
                        }
                      >
                        + Add New Attributes
                      </Button>
                    )}

                    {error && <sub className="text-danger">{error}</sub>}
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-md-6">
                <Table bordered style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      {/* {tableData?.map((value, index) => (
                        <>
                          <th>{value?.attribute?.name}</th>
                          {value?.s_p_v_a_one_ds?.map(
                            (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                              s_p_v_a_one_ds_index === 0 &&
                              s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                                (
                                  s_p_v_a_one_d_as_value,
                                  s_p_v_a_one_d_as_index
                                ) =>
                                  s_p_v_a_one_d_as_index === 0 && (
                                    <th
                                      key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}`}
                                    >
                                      {
                                        s_p_v_a_one_d_as_value?.s_attribute
                                          ?.name
                                      }{" "}
                                      {
                                        s_p_v_a_one_d_as_value?.s_attribute
                                          ?.defind
                                      }
                                    </th>
                                  )
                              )
                          )}
                          {value?.s_p_v_a_one_ds?.map(
                            (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                              s_p_v_a_one_ds_index === 0 &&
                              s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                                (
                                  s_p_v_a_one_d_as_value,
                                  s_p_v_a_one_d_as_index
                                ) =>
                                  s_p_v_a_one_ds_index === 0 &&
                                  s_p_v_a_one_d_as_index === 0 &&
                                  s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                                    (
                                      s_p_v_a_one_d_a_ds_value,
                                      s_p_v_a_one_d_a_ds_index
                                    ) =>
                                      s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                        (
                                          s_p_v_a_one_d_a_d_as_value,
                                          s_p_v_a_one_d_a_d_as_index
                                        ) =>
                                          s_p_v_a_one_d_a_d_as_index === 0 &&
                                          s_p_v_a_one_d_a_ds_index === 0 && (
                                            <th
                                              key={`${index}-${s_p_v_a_one_ds_index}-${s_p_v_a_one_d_as_index}-${s_p_v_a_one_d_a_ds_index}-${s_p_v_a_one_d_a_d_as_index}`}
                                            >
                                              {
                                                s_p_v_a_one_d_a_d_as_value
                                                  ?.s_attribute?.name
                                              }
                                              {
                                                s_p_v_a_one_d_a_d_as_value
                                                  ?.s_attribute?.defind
                                              }
                                            </th>
                                          )
                                      )
                                  )
                              )
                          )}
                        </>
                      ))} */}

                      {tableData?.p_as?.map((value, index) => (
                        <>
                          <th>{value?.attribute?.name}</th>
                          {value?.p_a_vs?.map(
                            (p_a_vs_value, p_a_vs_index) =>
                              p_a_vs_index === 0 &&
                              p_a_vs_value?.p_a_v_as?.map(
                                (p_a_v_a_value, p_a_v_a_index) =>
                                  p_a_v_a_index === 0 && (
                                    <th key={`${index}-${p_a_vs_index}`}>
                                      {p_a_v_a_value?.attribute?.name}{" "}
                                    </th>
                                  )
                              )
                          )}
                        </>
                      ))}

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.p_as?.map((value, index) =>
                      value?.p_a_vs?.map((p_a_vs_value, p_a_vs_index) =>
                        p_a_vs_value?.p_a_v_as?.length > 0 ? (
                          p_a_vs_value?.p_a_v_as?.map(
                            (p_a_v_as_value, p_a_v_as_index) =>
                              p_a_v_as_value?.p_a_v_a_vs?.map(
                                (p_a_v_a_vs_value, p_a_v_a_vs_index) => (
                                  <tr>
                                    <td>
                                      {p_a_vs_value?.sub_attribute?.name}{" "}
                                    </td>

                                    <td>
                                      {p_a_v_a_vs_value?.sub_attribute?.name}{" "}
                                    </td>

                                    <td>
                                      {p_a_v_a_vs_value?.status === 0 ? (
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            deleteAttribute(
                                              2,
                                              p_a_v_a_vs_value?.id
                                            )
                                          }
                                        >
                                          Add
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            deleteAttribute(
                                              2,
                                              p_a_v_a_vs_value?.id
                                            )
                                          }
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                          )
                        ) : (
                          <tr>
                            <td>{p_a_vs_value?.sub_attribute?.name} </td>

                            <td>
                              {p_a_vs_value?.status === 0 ? (
                                <Button
                                  variant="success"
                                  onClick={() =>
                                    deleteAttribute(1, p_a_vs_value?.id)
                                  }
                                >
                                  Add
                                </Button>
                              ) : (
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    deleteAttribute(1, p_a_vs_value?.id)
                                  }
                                >
                                  Remove
                                </Button>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    )}
                    {/* {tableData?.map((value, index) =>
                      value?.s_p_v_a_one_ds?.map(
                        (s_p_v_a_one_ds_value, s_p_v_a_one_ds_index) =>
                          s_p_v_a_one_ds_value?.s_p_v_a_one_d_as?.map(
                            (s_p_v_a_one_d_as_value, s_p_v_a_one_d_as_index) =>
                              s_p_v_a_one_d_as_value?.s_p_v_a_one_d_a_ds?.map(
                                (
                                  s_p_v_a_one_d_a_ds_value,
                                  s_p_v_a_one_d_a_ds_index
                                ) =>
                                  // level 2
                                  s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as
                                    .length ? (
                                    s_p_v_a_one_d_a_ds_value?.s_p_v_a_one_d_a_d_as?.map(
                                      (
                                        s_p_v_a_one_d_a_d_as_value,
                                        s_p_v_a_one_d_a_d_as_index
                                      ) =>
                                        s_p_v_a_one_d_a_d_as_value?.s_p_v_a_one_d_a_d_a_ds?.map(
                                          (
                                            s_p_v_a_one_d_a_d_a_ds_value,
                                            s_p_v_a_one_d_a_d_a_ds_index
                                          ) => (
                                            //level 3

                                            <tr>
                                              <td>
                                                {
                                                  s_p_v_a_one_ds_value
                                                    ?.s_sub_attribute?.name
                                                }{" "}
                                                {value?.s_attribute?.defind}{" "}
                                              </td>

                                              <td>
                                                {
                                                  s_p_v_a_one_d_a_ds_value
                                                    ?.s_sub_attribute?.name
                                                }{" "}
                                                {
                                                  s_p_v_a_one_d_as_value
                                                    ?.s_attribute?.defind
                                                }
                                              </td>

                                              <td>
                                                {
                                                  s_p_v_a_one_d_a_d_a_ds_value
                                                    ?.s_sub_attribute?.name
                                                }{" "}
                                                {
                                                  s_p_v_a_one_d_a_d_as_value
                                                    ?.s_attribute?.defind
                                                }{" "}
                                              </td>
                                              <td>
                                                {s_p_v_a_one_d_a_d_a_ds_value?.status ===
                                                0 ? (
                                                  <Button
                                                    variant="success"
                                                    onClick={() =>
                                                      deleteAttribute(
                                                        3,
                                                        s_p_v_a_one_d_a_d_a_ds_value?.id
                                                      )
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                      deleteAttribute(
                                                        3,
                                                        s_p_v_a_one_d_a_d_a_ds_value?.id
                                                      )
                                                    }
                                                  >
                                                    Remove
                                                  </Button>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )
                                    )
                                  ) : (
                                    <tr>
                                      <td>
                                        {
                                          s_p_v_a_one_ds_value?.s_sub_attribute
                                            ?.name
                                        }{" "}
                                        {value?.s_attribute?.defind}{" "}
                                      </td>

                                      <td>
                                        {
                                          s_p_v_a_one_d_a_ds_value
                                            ?.s_sub_attribute?.name
                                        }{" "}
                                        {
                                          s_p_v_a_one_d_as_value?.s_attribute
                                            ?.defind
                                        }
                                      </td>

                                      <td>
                                        {s_p_v_a_one_d_a_ds_value?.status ===
                                        0 ? (
                                          <Button
                                            variant="success"
                                            onClick={() =>
                                              deleteAttribute(
                                                2,
                                                s_p_v_a_one_d_a_ds_value?.id
                                              )
                                            }
                                          >
                                            Add
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              deleteAttribute(
                                                2,
                                                s_p_v_a_one_d_a_ds_value?.id
                                              )
                                            }
                                          >
                                            Remove
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                              )
                          )
                      )
                    )} */}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              {/* <Link>
                <CancelButton name={"cancle"} handleClose={props.handleClose} />
              </Link> */}

              <Button
                name={"Back"}
                onClick={handlePrevious}
                type="button"
                variant="danger"
                className="  me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-left"
                  className="me-2"
                />
                Back
              </Button>

              <Button
                name={"save"}
                onClick={handleSubmit(onSubmit)}
                type="button"
                className="save-btnnnnnn me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-floppy-disk"
                  className="me-2"
                />
                save
              </Button>

              {submitStatus && (
                <Button
                  name={"next"}
                  type="button"
                  className="save-btnnnnnn me-2"
                  onClick={handleNext}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-arrow-right"
                    className="me-2"
                  />
                  Next
                </Button>
              )}
            </div>
          </Row>
          {/* <div className="common-steps-btn">
            <button type="button" onClick={prevInnerPage} className="back-btn">
              Back
            </button>
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="next-btn"
            >
              Save
            </button>
            {submitStatus && (
              <button type="button" onClick={handleNext} className="next-btn">
                Next
              </button>
            )}
          </div> */}
        </section>

        {/* <section className="single-varient-sec">
          <div className="sec-div ">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-md-6">
                <div className="varient-firsttt">
                  {fields.map((item, index) => (
                    <div key={item.id}>
                      <Form>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Attribute {index + 1}
                            <span className="mandatory-star"> *</span>
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-info"
                              className="ms-3"
                            />
                          </Form.Label>

                          <Controller
                            name={`attribute_data[${index}].attribute_id`}
                            {...register(
                              `attribute_data[${index}].attribute_id`,
                              {
                                required: "Select Attribute",
                              }
                            )}
                            control={control}
                            render={({ field }) => (
                              <Select styles={{}} options={attributes} />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {
                              getValues(`attribute_data[${index}].attribute_id`)
                                ?.label
                            }{" "}
                            {index + 1}
                            <span className="mandatory-star"> *</span>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip-title"></Tooltip>}
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-info"
                                className="ms-3"
                              />
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Attributes"
                            className="attribute_data mb-2"
                          />

                          <Select styles={{}} />
                        </Form.Group>

                        {index > 1 && (
                          <Button
                            className="remove-btn-class "
                            onClick={() => remove(index)}
                          >
                            Remove
                          </Button>
                        )}
                      </Form>
                    </div>
                  ))}
                  <div className="text-end">
                    {fields.length < 3 && (
                      <Button
                        className="add-new-attribute-class"
                        onClick={() =>
                          append({ attribute_id: "", sub_attribute_id: [] })
                        }
                      >
                        + Add New Attributes
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-md-6">
                <div className="table-responsive">
                  <Table bordered style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <>
                          <th>1</th>

                          <th>2</th>

                          <th>3</th>
                        </>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ghjgh</td>

                        <td>ghfgh</td>

                        <td>hgfhg</td>
                        <td>
                          <Button
                            variant="success"
                            className="add-product-btn mx-1"
                          >
                            Add
                          </Button>
                          <Button
                            variant="danger"
                            className="remove-product-btn mx-1"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>

                      <tr>
                        <td>ghfgh</td>

                        <td>ghfgh</td>

                        <td>ghfgh</td>

                        <td>
                          <Button
                            variant="success"
                            className="add-product-btn mx-1"
                          >
                            Add
                          </Button>
                          <Button
                            variant="danger"
                            className="remove-product-btn mx-1"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
               <Link>
                <CancelButton name={"cancle"} handleClose={props.handleClose} />
              </Link> 

              <Button
                name={"Back"}
                onClick={handlePrevious}
                type="button"
                variant="danger"
                className="  me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-left"
                  className="me-2"
                />
                Back
              </Button>

              <Button
                name={"save"}
                onClick={handleSubmit(onSubmit)}
                type="button"
                className="save-btnnnnnn me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-floppy-disk"
                  className="me-2"
                />
                save
              </Button>

              <Button
                name={"next"}
                type="button"
                className="save-btnnnnnn me-2"
                onClick={handleNext}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-right"
                  className="me-2"
                />
                Next
              </Button>
            </div>
          </Row>
        </section> */}
      </fieldset>
    </>
  );
}

export default Step2;
