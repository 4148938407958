import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import SellerDetails from "./Components/sellerDetails/Table";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";
import EmployeeRequest from "./Components/EmployeeManagement/EmployeeRequests/Table";
import EmployeeRejected from "./Components/EmployeeManagement/EmployeeRejected/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import QuoteList from "./Components/CustomerManagement/QuoteList/Tables";
import CustomizeTapList from "./Components/CustomerManagement/CustomizeTapList/Tables";

import Product from "./Components/Products/Product/Table";
import ProductFeatures from "./Components/Products/ProductFeatures/Tables";
import Sale from "./Components/Products/Sale/Tables";
import ProductRequests from "./Components/Products/ProductRequests/Table";

import Specifications from "./Components/AboutUs/Specifications/Tables";
import OurMission from "./Components/AboutUs/OurMission/Tables";
import SocialMedia from "./Components/AboutUs/SocialMedia/Tables";
import Dignitaries from "./Components/AboutUs/Dignitaries/Table";
import HappyCustomers from "./Components/AboutUs/HappyCustomers/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";

import Orders from "./Components/OrderManagments/Orders/Table";
import CancelOrders from "./Components/OrderManagments/CancelOrders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";

// Settings
// Categories
import Category from "./Components/Masters/Category/Tables";
import SubCategory from "./Components/Masters/Sub_Category/Tables";
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables";
import Attributes from "./Components/Masters/Attribute/Tables";
import SubAttributes from "./Components/Masters/SubAttribute/Tables";
// Why
import WhyChooseUs from "./Components/Why/WhyChooseUs/Tables";
import WhyCMJ from "./Components/Why/WhyCMJ/Tables";

// Customization
import WebHeader from "./Components/Masters/WebHeader/Tables";
import AppSetup from "./Components/Masters/AppSetup/Tables";
import HomeBanner from "./Components/Masters/S_Banner/Tables";
import Home_side_banner from "./Components/Masters/Home_side_banner/Tables";
import HomeAdBanner from "./Components/Masters/Ad_Banner/Tables";
import BestEssential from "./Components/Masters/BestEssential/Tables";
import WhatPeopleSay from "./Components/Masters/WhatPeopleSay/Tables";
import Blogs from "./Components/Masters/Blogs/Tables";
import PopularTags from "./Components/Masters/PopularTags/Tables";
import FaqCategory from "./Components/Masters/FaqCategory/Tables";
import Faq from "./Components/Masters/FAQs/Tables";
import HelpCategory from "./Components/Masters/HelpCategory/Tables";
import Help from "./Components/Masters/Help/Tables";
// import OurMission from "./Components/Masters/OurMission/Table";

import CategoryBanner from "./Components/Masters/category_Banner/Tables";
import TrendingProduct from "./Components/Masters/TrendingProduct/Tables";

import Order_cancel_reason from "./Components/Masters/Order_cancel_reason/Tables";
import Order_return_reason from "./Components/Masters/Order_return_reason/Tables";

import CouponCodes from "./Components/Masters/CouponCodes/Table";
import Brands from "./Components/Masters/Brands/Tables";

// Location
import Country from "./Components/Masters/Country/Tables";
import State from "./Components/Masters/State/Tables";
import City from "./Components/Masters/City/Tables";
import Pincode from "./Components/Masters/Pincode/Tables";
import CountryCodes from "./Components/Masters/CountryCode/Tables";

// Tax
import TaxType from "./Components/Masters/Tax_Types/Tables";
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables";

//Content
import Privacy from "./Components/Content/Privacy/Tables";
import Terms from "./Components/Content/Terms/Tables";
import ReturnPolicy from "./Components/Content/ReturnPolicy/Tables";
import Shipping from "./Components/Content/Shipping/Tables";
import Cancellation from "./Components/Content/Cancellation/Tables";
import PaymentMethod from "./Components/Content/PaymentMethod/Tables";
import SecurityTrust from "./Components/Content/SecurityTrust/Tables";

// Seller

const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${
              isSidebarOpen
                ? "sidebarHeadopen "
                : "scale-in-hor-left sidebarHeadClose"
            }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${
              isSidebarOpen
                ? "dashboradopen"
                : "scale-in-hor-right dashboradopen"
            }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/advanceDashboard"
                  element={<AdvanceDashboard />}
                />
                <Route path="/settingContent" element={<SettingContent />} />
                <Route path="/employee/role" element={<Role />} />
                <Route path="/seller-detail" element={<SellerDetails />} />
                <Route
                  path="/employee/employee-details"
                  element={<EmployeeDetails />}
                />
                <Route
                  path="/employee/employee-requests"
                  element={<EmployeeRequest />}
                />

                <Route
                  path="/employee/employee-rejected"
                  element={<EmployeeRejected />}
                />

                <Route
                  path="/employee/customer-details"
                  element={<CustomerDetails />}
                />
                <Route path="/employee/contact-us" element={<ContactUs />} />
                <Route path="/employee/quote" element={<QuoteList />} />
                <Route
                  path="/employee/customize-tap"
                  element={<CustomizeTapList />}
                />

                {/* Product  */}
                <Route path="/product" element={<Product />} />
                <Route path="/product-requests" element={<ProductRequests />} />
                <Route path="/product/:id" element={<Product />} />
                <Route
                  path="/product/product-features"
                  element={<ProductFeatures />}
                />
                <Route path="/product/sale" element={<Sale />} />
                {/* About us Start */}

                <Route
                  path="/about-us/specifications"
                  element={<Specifications />}
                />
                <Route path="/about-us/our-mission" element={<OurMission />} />
                <Route
                  path="/about-us/social-media"
                  element={<SocialMedia />}
                />
                <Route path="/about-us/dignitaries" element={<Dignitaries />} />
                <Route
                  path="/about-us/happy-customers"
                  element={<HappyCustomers />}
                />
                <Route path="/about-us/our-story" element={<OurStory />} />
                <Route
                  path="/about-us/about-banner"
                  element={<AboutBanner />}
                />

                <Route path="/order-managements/order" element={<Orders />} />

                <Route path="/order-managements/orders" element={<Orders />} />
                <Route
                  path="/order-managements/cancel-orders"
                  element={<CancelOrders />}
                />
                <Route
                  path="/order-managements/order/refund"
                  element={<OrdersToRefund />}
                />
                <Route
                  path="/order-managements/order/return"
                  element={<ReturnOrders />}
                />

                {/* ******************************     Content Policy    ********************************** */}
                <Route path="/content/privacy" element={<Privacy />} />
                <Route path="/content/terms" element={<Terms />} />
                <Route
                  path="/content/return-policy"
                  element={<ReturnPolicy />}
                />
                <Route path="/content/shipping" element={<Shipping />} />
                <Route
                  path="/content/cancellation"
                  element={<Cancellation />}
                />
                <Route
                  path="/content/payment-method"
                  element={<PaymentMethod />}
                />
                <Route
                  path="/content/security-trust"
                  element={<SecurityTrust />}
                />
                {/* ******************************     Settings   ********************************** */}

                {/* Order_cancel_reason */}
                <Route
                  path="/settings/order-cancel-reason"
                  element={<Order_cancel_reason />}
                />
                <Route
                  path="/settings/order-return-reason"
                  element={<Order_return_reason />}
                />
                {/* **********************   Categories   ******************************* */}
                <Route path="/settings/category" element={<Category />} />
                <Route
                  path="/settings/sub-category"
                  element={<SubCategory />}
                />
                <Route
                  path="/settings/child-sub-category"
                  element={<ChildSubCategory />}
                />

                {/* **********************   Attributes   ******************************* */}

                <Route path="/settings/attribute" element={<Attributes />} />
                <Route
                  path="/settings/sub-attribute"
                  element={<SubAttributes />}
                />

                {/* **********************   Why Section    ******************************* */}
                <Route path="/why/why-choose-us" element={<WhyChooseUs />} />
                <Route path="/why/why-cmj" element={<WhyCMJ />} />
                {/* **********************   Customization   ******************************* */}
                <Route
                  path="/settings/customization/web-header"
                  element={<WebHeader />}
                />
                <Route
                  path="/settings/customization/trending-product"
                  element={<TrendingProduct />}
                />
                <Route
                  path="/settings/customization/app-setup"
                  element={<AppSetup />}
                />
                <Route
                  path="/settings/customization/home-banner"
                  element={<HomeBanner />}
                />
                <Route
                  path="/settings/customization/home-side-banner"
                  element={<Home_side_banner />}
                />
                <Route
                  path="/settings/customization/home-ad-banner"
                  element={<HomeAdBanner />}
                />

                <Route
                  path="/settings/customization/best-essential"
                  element={<BestEssential />}
                />

                <Route
                  path="/settings/customization/what-people-say"
                  element={<WhatPeopleSay />}
                />
                <Route
                  path="/settings/customization/category-banner"
                  element={<CategoryBanner />}
                />
                <Route
                  path="/settings/customization/blogs"
                  element={<Blogs />}
                />

                <Route
                  path="/settings/customization/popular-tags"
                  element={<PopularTags />}
                />
                <Route
                  path="/settings/customization/faq-category"
                  element={<FaqCategory />}
                />
                <Route path="/settings/customization/faqs" element={<Faq />} />
                <Route path="/settings/customization/help" element={<Help />} />
                <Route
                  path="/settings/customization/help-category"
                  element={<HelpCategory />}
                />

                <Route
                  path="/settings/Coupon-codes"
                  element={<CouponCodes />}
                />
                <Route path="/settings/brands" element={<Brands />} />

                {/* **********************    Location  ******************************* */}

                <Route
                  path="/settings/location/country"
                  element={<Country />}
                />
                <Route path="/settings/location/state" element={<State />} />
                <Route path="/settings/location/city" element={<City />} />
                <Route
                  path="/settings/location/pincode"
                  element={<Pincode />}
                />
                <Route
                  path="/settings/location/country-codes"
                  element={<CountryCodes />}
                />

                {/* **********************    Tax  ******************************* */}
                <Route path="/settings/tax/tax-type" element={<TaxType />} />
                <Route
                  path="/settings/tax/tax-percentage"
                  element={<TaxPercentage />}
                />
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
