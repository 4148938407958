import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Attributes = async () => {
  try {
    return await getData(`/all-attribute`);
  } catch (error) {
    console.log(error);
  }
};

export const SubAttributes = async (id) => {
  try {
    return await getData(`/all-sub-attribute/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/allcountry`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/allstates/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/allcity/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const PincodeByCity = async (id) => {
  try {
    return await getData(`/allpincode/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (search = "") => {
  try {
    return await getData(`/allpincodes?search=${search}`);
  } catch (error) {
    console.log(error);
  }
};

export const CountryCode = async () => {
  try {
    return await getData(`/allcountrycode`);
  } catch (error) {
    console.log(error);
  }
};

export const Category = async () => {
  try {
    return await getData(`/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildCategory = async (id) => {
  try {
    return await getData(`/allchildcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Brand = async () => {
  try {
    return await getData(`/allbrands`);
  } catch (error) {
    console.log(error);
  }
};

export const Area = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-area`);
  } catch (error) {
    console.log(error);
  }
};

export const FaqCategory = async () => {
  try {
    return await getData(`/allFaqCategories`);
  } catch (error) {
    console.log(error);
  }
};

export const Faq = async (id) => {
  try {
    return await getData(`/allFaq/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Days = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-days`);
  } catch (error) {
    console.log(error);
  }
};

export const FirmType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-firm-type`);
  } catch (error) {
    console.log(error);
  }
};

export const GST = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-gst`);
  } catch (error) {
    console.log(error);
  }
};

export const OrderStatus = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfo = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-product-info`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceTerm = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-term`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-type`);
  } catch (error) {
    console.log(error);
  }
};

export const Unit = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-unit`);
  } catch (error) {
    console.log(error);
  }
};

export const VarientType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-varient-type`);
  } catch (error) {
    console.log(error);
  }
};

export const HelpCategory = async () => {
  try {
    return await getData(`/all-help-category`);
  } catch (error) {
    console.log(error);
  }
};

export const Features = async () => {
  try {
    return await getData(`/all-features`);
  } catch (error) {
    console.log(error);
  }
};
