export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const cuppon_type = {
  percentage: "percentage",
  fixedamount: "fixedamount",
};

export const product_type = {
  B2B: "Bulk Order",
  B2C: "Home Page",
  SampleRoll: "Sample Roll",
};

export const ImageValidation = {
  files_type: ["jpg", "png", "jpeg"],
  product: { w: 578, h: 762 },
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export const ProductNarrationChange = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(" ")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return capitalizedValue;
};

export const ProductNarrationChangeComa = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(",")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(",");
  return capitalizedValue;
};

export const validateImage = async (file, size = 500) => {
  if (!file) {
   
    return false;
  }

  return true;
  // if (!file) return "Image is required";

  // Check file size (500KB = 500 * 1024 bytes)
  const maxSizeInBytes = size * 1024;
  if (file.size > maxSizeInBytes) {
   
    return false;
    // return "Image size must be less than or equal to 500KB";
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const aspectRatio = img.width / img.height;
    
      if (aspectRatio.toFixed(1) == 0.5) {
        resolve(true);
      } else {
       
        resolve(false);
        // resolve("Image aspect ratio must be 1:2 (width:height)");
      }
    };
    img.onerror = () => {
  
      resolve(false);
      // resolve("Invalid image file");
    };
  });
};

export const validateVideo = async (file, size = 5, duration = 40) => {
  return new Promise((resolve) => {
    if (!file) {
      resolve(false);
      return;
    }

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const v_duration = video.duration;
      const v_size = file.size / (1024 * 1024); // size in MB


      if (v_duration > duration) {
        resolve(false);
      } else if (v_size > size) {
        resolve(false);
      } else {
        resolve(true);
      }
    };

    video.src = URL.createObjectURL(file);
  });
};
