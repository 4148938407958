import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageValidation } from "../../../utils/common";
import { ValidationImage } from "../../../utils/api";
import ProductEdit from "./ProductEdit/ProductEdit";
library.add(fas);

const EditOffCanvance = (props) => {
  // const {
  //   postData,
  //   getData,
  //   Select2Data,
  //   IMG_URL,
  //   getDimension,
  //   deleteData,
  //   isAllow,
  // } = useContext(Context);

  // const [categories, setCategories] = useState([]);
  // const [units, setUnits] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  // const [childCategories, setchildCategories] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [taxTypes, setTaxTypes] = useState([]);
  // const [taxPercentages, setPercentages] = useState([]);
  // const [attributes, setAttribute] = useState([]);
  // const [subAttributes, setSubAttribute] = useState([]);
  // const id = props.show;
  // const [role, setRole] = useState([]);
  // const [data, setData] = useState();
  // const [isDisabled, setIsDisabled] = useState(false);

  // const [user, setUser] = useState({});
  // const GetUserData = async () => {
  //   const response = await getData(`/usersingleget`);
  //   if (response?.success) {
  //     console.log("user :-", response?.data);
  //     setUser(response?.data);
  //   }
  // };
  // useEffect(() => {
  //   GetUserData();
  // }, []);

  // useEffect(() => {
  //   console.log("user?.id == ", user?.id);
  //   console.log("i am here ", user);
  //   if (user?.id === 1) {
  //     setIsDisabled(true);
  //   }
  // }, [user]);

  // console.log("isDisabled ", isDisabled);

  // const [showModal, setShowModal] = useState({
  //   code: 0,
  //   message: "",
  // });

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   getValues,
  //   formState: { errors },
  //   watch,
  //   setValue,
  //   reset,
  // } = useForm();

  // const imageFile = watch("image");
  // // console.log("imagesFile:", imagesFile);

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "quantity", // Name of your field array
  // });

  // const [quantityError, setquantityError] = useState("");

  // useEffect(() => {
  //   register("quantity", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setquantityError(isValid ? "" : "At least one quantity is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  // const validateSellingPrice = (value, index) => {
  //   const mrpValue = parseFloat(getValues(`quantity.${index}.mrp`));
  //   const sellingPrice = parseFloat(value);
  //   if (sellingPrice >= mrpValue) {
  //     return "Selling price must be less than MRP";
  //   }
  //   return true; // Validation passed
  // };

  // const onSubmit = async (data) => {
  //   try {
  //     const DataToSend = new FormData();
  //     DataToSend.append("tax_percentage", data?.tax_percentage);
  //     DataToSend.append("category_id", data?.category_id?.value);
  //     DataToSend.append("sub_category_id", data?.sub_category_id?.value);
  //     DataToSend.append("child_category_id", data?.child_category_id?.value);
  //     DataToSend.append("name", data?.name);
  //     DataToSend.append("short_description", data?.short_description);
  //     DataToSend.append("properties", data?.properties);
  //     DataToSend.append("safety_information", data?.safety_information);
  //     DataToSend.append("image", data?.image[0]);
  //     // Append quantity
  //     const quantity = [];
  //     data.quantity.forEach((variant, index) => {
  //       quantity.push({
  //         quantity: variant.quantity,
  //         unit_id: variant.unit_id.value,
  //         price: variant.price,
  //       });
  //     });

  //     DataToSend.append("quantity", JSON.stringify(quantity));
  //     const response = await postData(`/product/${id}`, DataToSend);
  //     console.log("response", response);
  //     console.log("data to send", DataToSend);
  //     if (response?.success) {
  //       await setShowModal({ code: response.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const GetAllCategory = async () => {
  //   {
  //     const response = await getData("/allcategories");

  //     if (response?.success) {
  //       setCategories(await Select2Data(response?.data, "category_id"));
  //     }
  //   }

  //   {
  //     const response = await getData("/all-units");

  //     if (response?.success) {
  //       setUnits(await Select2Data(response?.data, "unit_id"));
  //     }
  //   }
  // };

  // const GetAllSubCategory = async (id) => {
  //   const response = await getData(`/allsubcategories/${id}`);

  //   if (response?.success) {
  //     setSubCategories(await Select2Data(response?.data, "sub_category_id"));
  //   }
  // };

  // const GetAllChildSubCategory = async (id) => {
  //   const response = await getData(`/allchildcategories/${id}`);

  //   if (response?.success) {
  //     setchildCategories(await Select2Data(response?.data, "sub_category_id"));
  //   }
  // };

  // useEffect(() => {
  //   GetAllCategory();
  // }, []);
  // const GetEditData = async () => {
  //   const response = await getData(`/product/${id}`);
  //   reset(response?.data);
  // };

  // useEffect(() => {
  //   GetEditData();
  // }, []);

  // useEffect(() => {
  //   register("quantity", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setquantityError(isValid ? "" : "At least one Quantity is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Product</Card.Title>
              <hr />
              <Container>
                <ProductEdit
                  product_id={props.show}
                  OffcanvasCloseButton={props.handleClose}
                />
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      /> */}
    </>
  );
};

export default EditOffCanvance;
