// import React, { useContext } from "react";
// import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
// import { Link } from "react-router-dom";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import "./Product.css";

// import Card from "react-bootstrap/Card";
// import Select from "react-select";
// import ModalSave from "../../common/ModelSave";
// import { CancelButton, SaveButton } from "../../common/Button";
// import {
//   Row,
//   Col,
//   Form,
//   Modal,
//   Container,
//   InputGroup,
//   Button,
// } from "react-bootstrap";
// import { useForm, Controller, useFieldArray } from "react-hook-form";
// import classNames from "classnames";
// import { Context } from "../../../utils/context";
// import JoditEditor from "jodit-react";
// import Table from "react-bootstrap/Table";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ImageValidation } from "../../../utils/common";
// import { ValidationImage } from "../../../utils/api";

// const AddOffCanvance = (props) => {
//   const { postData, getData, Select2Data, getDimension } = useContext(Context);

//   const [categories, setCategories] = useState([]);
//   const [units, setUnits] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [childCategories, setchildCategories] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [taxTypes, setTaxTypes] = useState([]);
//   const [taxPercentages, setPercentages] = useState([]);
//   const [attributes, setAttribute] = useState([]);
//   const [subAttributes, setSubAttribute] = useState([]);

//   const [showModal, setShowModal] = useState({
//     code: 0,
//     message: "",
//   });

//   const {
//     control,
//     register,
//     handleSubmit,
//     getValues,
//     formState: { errors },
//     watch,
//     setValue,
//   } = useForm();
//   const imagesFile = watch("images");
//   const imageFile = watch("image");

//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "quantity",
//   });

//   const [quantityError, setquantityError] = useState("");

//   useEffect(() => {
//     register("quantity", {
//       validate: (value) => {
//         const isValid = value && value.length > 0;
//         setquantityError(isValid ? "" : "At least one Quantity is required");
//         return isValid;
//       },
//     });
//   }, [register]);

//   const validateSellingPrice = (value, index) => {
//     const mrpValue = parseFloat(getValues(`quantity.${index}.mrp`));
//     const sellingPrice = parseFloat(value);
//     if (sellingPrice >= mrpValue) {
//       return "Selling price must be less than MRP";
//     }
//     return true; // Validation passed
//   };

//   const onSubmit = async (data) => {
//     try {
//       const DataToSend = new FormData();
//       DataToSend.append("tax_percentage", data?.tax_percentage);
//       DataToSend.append("category_id", data?.category_id?.value);
//       DataToSend.append("sub_category_id", data?.sub_category_id?.value);
//       DataToSend.append("child_category_id", data?.child_category_id?.value);
//       DataToSend.append("name", data?.name);
//       DataToSend.append("short_description", data?.short_description);
//       DataToSend.append("properties", data?.properties);
//       DataToSend.append("safety_information", data?.safety_information);
//       DataToSend.append("image", data?.image[0]);

//       // DataToSend.append("manufacturer", data?.manufacturer);
//       // DataToSend.append("vender", data?.vender);
//       // DataToSend.append("storage", data?.storage);
//       // DataToSend.append("brand_id", data?.brand_id?.value);
//       // DataToSend.append("tax_type_id", data?.tax_type_id?.value);
//       // DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);

//       // Append quantity
//       const quantity = [];
//       data.quantity.forEach((variant, index) => {
//         quantity.push({
//           quantity: variant.quantity,
//           unit_id: variant.unit_id.value,
//           price: variant.price,
//         });

//         // let inputImages = document.getElementById(`variantImages${index}`);
//         // console.log(inputImages.files);
//         // const files = inputImages.files;
//         // for (let i = 0; i < files.length; i++) {
//         //   DataToSend.append(`images${index}`, files[i]);
//         // }
//       });

//       DataToSend.append("quantity", JSON.stringify(quantity));
//       const response = await postData(`/product`, DataToSend);
//       console.log("response", response);
//       console.log("data to send", DataToSend);
//       if (response?.success) {
//         await setShowModal({
//           code: response?.code,
//           message: response?.message,
//         });
//       } else {
//         await setShowModal({
//           code: response?.code,
//           message: response?.message,
//         });
//       }
//       setTimeout(() => {
//         setShowModal(0);
//         props.handleClose();
//       }, 1000);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const GetAllCategory = async () => {
//     {
//       const response = await getData("/allcategories");

//       if (response?.success) {
//         setCategories(await Select2Data(response?.data, "category_id"));
//       }
//     }

//     {
//       const response = await getData("/all-units");

//       if (response?.success) {
//         setUnits(await Select2Data(response?.data, "unit_id"));
//       }
//     }
//   };

//   const GetAllSubCategory = async (id) => {
//     const response = await getData(`/allsubcategories/${id}`);

//     if (response?.success) {
//       setSubCategories(await Select2Data(response?.data, "sub_category_id"));
//     }
//   };

//   const GetAllChildSubCategory = async (id) => {
//     const response = await getData(`/allchildcategories/${id}`);

//     if (response?.success) {
//       setchildCategories(await Select2Data(response?.data, "sub_category_id"));
//     }
//   };

//   const GetAllBrands = async () => {
//     const response = await getData("/allbrands");

//     if (response?.success) {
//       setBrands(await Select2Data(response?.data, "brand_id"));
//     }
//   };

//   const GetAllTaxTypes = async () => {
//     const response = await getData("/all-tax-type");

//     if (response?.success) {
//       setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
//     }
//   };

//   const GetAllTaxPercentages = async (id) => {
//     const response = await getData(`/all-tax-percentage/${id}`);

//     if (response?.success) {
//       setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
//     }
//   };

//   const GetAllAttributes = async () => {
//     const response = await getData(`/all-attribute`);

//     if (response?.success) {
//       setAttribute(await Select2Data(response?.data, "attribute_id"));
//     }
//   };

//   const GetAllSubAttributes = async (id) => {
//     const response = await getData(`/all-sub-attribute/${id}`);

//     if (response?.success) {
//       setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
//     }
//   };

//   console.log(getValues("images[0]"), "llll");

//   useEffect(() => {
//     GetAllCategory();
//     GetAllBrands();
//     GetAllTaxTypes();
//     GetAllAttributes();
//   }, []);

//   const deleteImage = async (index) => {
//     const updatedImagesFile = [...imagesFile];
//     updatedImagesFile.splice(index, 1);
//     setValue("images", updatedImagesFile);
//   };

//   return (
//     <>
//       <div className="prodcuttttt">
//         <Offcanvas
//           show={props.show}
//           style={{ width: "80%" }}
//           placement={"end"}
//           onHide={props.handleClose}
//         >
//           <Offcanvas.Header closeButton>
//             {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
//           </Offcanvas.Header>
//           <Offcanvas.Body>
//             <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//               <Card.Body>
//                 <Card.Title>Add Product</Card.Title>
//                 <hr />
//                 <Container>
//                   <Form
//                     // onSubmit={() => handleSubmit(onSubmit)}
//                     role="form"
//                     // className="stateclass"
//                   >
//                     <Row>
//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Category</Form.Label>
//                             <Controller
//                               name="category_id" // name of the field
//                               {...register("category_id", {
//                                 required: "Select Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={categories}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllSubCategory(selectedOption.value);
//                                     setValue("category_id", selectedOption);
//                                     setValue("sub_category_id", "");
//                                     setValue("child_category_id", "");
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.category_id && (
//                               <span className="text-danger">
//                                 {errors.category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Sub Category</Form.Label>
//                             <Controller
//                               name="sub_category_id" // name of the field
//                               {...register("sub_category_id", {
//                                 required: "Select Sub Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.sub_category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={subCategories}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllChildSubCategory(
//                                       selectedOption.value
//                                     );
//                                     setValue("sub_category_id", selectedOption);
//                                     setValue("child_category_id", "");
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.sub_category_id && (
//                               <span className="text-danger">
//                                 {errors.sub_category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Child Category</Form.Label>
//                             <Controller
//                               name="child_category_id" // name of the field
//                               {...register("child_category_id", {
//                                 required: "Select Child Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.child_category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={childCategories}
//                                 />
//                               )}
//                             />
//                             {errors.child_category_id && (
//                               <span className="text-danger">
//                                 {errors.child_category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>Name</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   type="text"
//                                   name="name"
//                                   placeholder="Name .."
//                                   className={classNames("", {
//                                     "is-invalid": errors?.name,
//                                   })}
//                                   {...register("name", {
//                                     required: "Name is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.name && (
//                                 <span className="text-danger">
//                                   {errors.name.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>Short Description</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   type="text"
//                                   name="short_description"
//                                   placeholder="Short Description"
//                                   maxLength={40}
//                                   className={classNames("", {
//                                     "is-invalid": errors?.short_description,
//                                   })}
//                                   {...register("short_description", {
//                                     required: "Short Description is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.short_description && (
//                                 <span className="text-danger">
//                                   {errors.short_description.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Form.Label>Image</Form.Label>

//                           <Form.Group>
//                             <Form.Control
//                               className={classNames("", {
//                                 "is-invalid": errors?.image,
//                               })}
//                               type="file"
//                               {...register("image", {
//                                 required: "images is required",
//                                 validate: async (value) => {
//                                   return await ValidationImage(
//                                     value,
//                                     ImageValidation.product.w,
//                                     ImageValidation.product.h
//                                   );
//                                 },
//                               })}
//                               accept="image/*"
//                             />
//                           </Form.Group>
//                           {errors.image && (
//                             <span className="text-danger">
//                               {errors.image.message}
//                             </span>
//                           )}
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Form.Label>image</Form.Label>
//                           {imageFile && imageFile?.length > 0 && (
//                             <div className="image-preview-container">
//                               <img
//                                 // src={URL.createObjectURL(getValues("image")[0])}
//                                 src={URL?.createObjectURL(imageFile[0])}
//                                 alt="Preview"
//                                 className="image-preview"
//                                 style={{ width: "100px", height: "100px" }}
//                               />
//                             </div>
//                           )}
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>Tax Percentage</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   type="number"
//                                   name="tax_percentage"
//                                   placeholder="Tax Percentage"
//                                   maxLength={40}
//                                   className={classNames("", {
//                                     "is-invalid": errors?.tax_percentage,
//                                   })}
//                                   {...register("tax_percentage", {
//                                     required: "Tax Percentage is required",
//                                     pattern: {
//                                       value: /^[0-9][0-9]?$|^100$/,
//                                       message:
//                                         "Please enter a number between 0 and 100",
//                                     },
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.tax_percentage && (
//                                 <span className="text-danger">
//                                   {errors.tax_percentage.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       <div className="main-form-section mt-5"></div>
//                       <Card.Title>Product Details</Card.Title>
//                       <hr />

//                       {/* <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>manufacturer</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   type="text"
//                                   name="manufacturer"
//                                   placeholder="manufacturer"
//                                   className={classNames("", {
//                                     "is-invalid": errors?.manufacturer,
//                                   })}
//                                   {...register("manufacturer", {
//                                     required: "manufacturer is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.manufacturer && (
//                                 <span className="text-danger">
//                                   {errors.manufacturer.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>

//                           <Row className="justify-content-center">
//                             <Form.Label>vender</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   type="text"
//                                   name="vender"
//                                   placeholder="vender"
//                                   className={classNames("", {
//                                     "is-invalid": errors?.vender,
//                                   })}
//                                   {...register("vender", {
//                                     required: "vender is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.vender && (
//                                 <span className="text-danger">
//                                   {errors.vender.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col> */}

//                       {/* <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Brand</Form.Label>
//                             <Controller
//                               name="brand_id" // name of the field
//                               {...register("brand_id", {
//                                 required: "Select Brand",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.brand_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={brands}
//                                 />
//                               )}
//                             />
//                             {errors.brand_id && (
//                               <span className="text-danger">
//                                 {errors.brand_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col> */}

//                       {/* Tax Type */}
//                       {/* <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Tax Type</Form.Label>
//                             <Controller
//                               name="tax_type_id" // name of the field
//                               {...register("tax_type_id", {
//                                 required: "Select Tax Type",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.tax_type_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={taxTypes}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllTaxPercentages(selectedOption.value);
//                                     setValue("tax_type_id", selectedOption);
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.tax_type_id && (
//                               <span className="text-danger">
//                                 {errors.tax_type_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col> */}

//                       {/* Tax Percentage */}
//                       {/* <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Tax Percentage</Form.Label>
//                             <Controller
//                               name="tax_percentage_id" // name of the field
//                               {...register("tax_percentage_id", {
//                                 required: "Select Tax Percentage",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.tax_percentage_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={taxPercentages}
//                                 />
//                               )}
//                             />
//                             {errors.tax_percentage_id && (
//                               <span className="text-danger">
//                                 {errors.tax_percentage_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col> */}

//                       <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             {" "}
//                             {/* Align to left */}
//                             {/* Description */}
//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 Properties
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="properties" // Provide the field name
//                                   control={control} // Pass the control object from useForm()
//                                   rules={{
//                                     required: "Properties is required",
//                                   }} // Validation rules
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid": !!errors.properties,
//                                       })}
//                                       placeholder="Properties"
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.properties && (
//                                 <span className="text-danger">
//                                   {errors.properties.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 Safety Information
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="safety_information" // Provide the field name
//                                   control={control} // Pass the control object from useForm()
//                                   rules={{
//                                     required: "safety information is required",
//                                   }} // Validation rules
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid":
//                                           !!errors.safety_information,
//                                       })}
//                                       placeholder="safety_information"
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.safety_information && (
//                                 <span className="text-danger">
//                                   {errors.safety_information.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col>

//                       {/* <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             {" "}

//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 storage
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="storage" // Provide the field name
//                                   control={control} // Pass the control object from useForm()
//                                   rules={
//                                     {
//                                       // required: "storage is required"
//                                     }
//                                   } // Validation rules
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid": !!errors.storage,
//                                       })}
//                                       placeholder="storage"
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.storage && (
//                                 <span className="text-danger">
//                                   {errors.storage.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col> */}

//                       {/* images Preview */}
//                       {/* <Col lg={12}>
//                       <div className="main-form-section mt-3">
//                         <Row className="justify-content-center">
//                           <Form.Label column sm={3} className="text-center">
//                             images Preview
//                           </Form.Label>
//                           <Col sm={9}>
//                             {imagesFile && imagesFile?.length > 0 && (
//                               <div className="images-preview-container">
//                                 <img
//                                   src={URL.createObjectURL(imagesFile[0])}
//                                   alt="Preview"
//                                   className="images-preview"
//                                   style={{ width: "100px", height: "100px" }}
//                                 />
//                               </div>
//                             )}
//                           </Col>
//                         </Row>
//                       </div>
//                     </Col> */}

//                       <div className="main-form-section mt-5"></div>
//                       <Card.Title>
//                         Product Quantity Details
//                         {quantityError && (
//                           <div className="text-danger">{quantityError}</div>
//                         )}
//                       </Card.Title>
//                       <hr />

//                       {fields.map((variant, index) => (
//                         <div
//                           key={variant.id}
//                           className="main-form-section mt-3"
//                         >
//                           <Row>
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>Quantity</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         type="number"
//                                         name={`quantity.${index}.quantity`}
//                                         placeholder="Quantity"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.quantity?.[index]?.quantity, // Updated error handling
//                                         })}
//                                         {...register(
//                                           `quantity.${index}.quantity`,
//                                           {
//                                             required: "Quantity is required",
//                                           }
//                                         )}
//                                       />
//                                     </InputGroup>
//                                     {errors?.quantity?.[index]?.quantity && (
//                                       <span className="text-danger">
//                                         {
//                                           errors?.quantity?.[index]?.quantity
//                                             .message
//                                         }
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="row justify-content-center mb-2">
//                                   <Form.Label>Unit</Form.Label>
//                                   <Controller
//                                     name={`quantity.${index}.unit_id`}
//                                     {...register(`quantity.${index}.unit_id`, {
//                                       required: "Select Unit",
//                                     })}
//                                     control={control}
//                                     render={({ field }) => (
//                                       <Select
//                                         styles={{
//                                           control: (baseStyles) => ({
//                                             ...baseStyles,
//                                             borderColor: errors?.quantity?.[
//                                               index
//                                             ]?.unit_id
//                                               ? "red"
//                                               : baseStyles,
//                                           }),
//                                         }}
//                                         {...field}
//                                         options={units}
//                                         onChange={(selectedOption) => {
//                                           field.onChange(selectedOption.value); // Update Controller's value

//                                           setValue(
//                                             `quantity.${index}.unit_id`,
//                                             selectedOption
//                                           );
//                                         }}
//                                       />
//                                     )}
//                                   />
//                                   {errors?.quantity?.[index]?.unit_id && (
//                                     <span className="text-danger">
//                                       {
//                                         errors?.quantity?.[index]?.unit_id
//                                           .message
//                                       }
//                                     </span>
//                                   )}
//                                 </Row>
//                               </div>
//                             </Col>

//                             {/* Price */}
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>Price</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         type="number"
//                                         name={`quantity.${index}.price`}
//                                         placeholder="Price"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.quantity?.[index]?.price, // Updated error handling
//                                         })}
//                                         {...register(
//                                           `quantity.${index}.price`,
//                                           {
//                                             required: "Price is required",
//                                           }
//                                         )}
//                                       />
//                                     </InputGroup>
//                                     {errors?.quantity?.[index]?.price && (
//                                       <span className="text-danger">
//                                         {errors.quantity[index].price.message}
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             {/* <Col lg={6}>
//                               <div className="main-form-section mt-3">
//                                 <Form.Label>images</Form.Label>

//                                 <Form.Group>
//                                   <Form.Control
//                                     className={classNames("", {
//                                       "is-invalid": errors?.image,
//                                     })}
//                                     type="file"
//                                     multiple
//                                     {...register(`quantity.${index}.image`, {
//                                       required: "images is required",
//                                     })}
//                                     id={`variantImages${index}`}
//                                     accept="image/*"
//                                   />
//                                 </Form.Group>
//                                 {errors.quantity?.[index]?.image && (
//                                   <span className="text-danger">
//                                     {errors.quantity?.[index]?.image.message}
//                                   </span>
//                                 )}
//                               </div>
//                             </Col> */}
//                             <Col lg={3}>
//                               <button
//                                 className="mt-3 add-varient"
//                                 type="button"
//                                 onClick={() => remove(index)}
//                               >
//                                 Remove Quantity
//                               </button>
//                             </Col>
//                           </Row>
//                         </div>
//                       ))}
//                       <div className="main-form-section mt-3"></div>
//                       <hr />
//                       <div className="text-center">
//                         <button
//                           type="button"
//                           className="add-varient"
//                           onClick={() => append({})}
//                         >
//                           + Add Quantity
//                         </button>
//                       </div>

//                       <Row className="mt-5 pb-3">
//                         <div className="d-flex justify-content-center">
//                           <Link>
//                             <CancelButton
//                               name={"cancel"}
//                               handleClose={props.handleClose}
//                             />
//                           </Link>

//                           <Button
//                             name={"save"}
//                             onClick={handleSubmit(onSubmit)}
//                             type="button"
//                             className="save-btnnnnnn me-2"
//                           >
//                             <FontAwesomeIcon
//                               icon="fa-solid fa-floppy-disk"
//                               className="me-2"
//                             />
//                             save
//                           </Button>

//                           <Button
//                             name={"next"}
//                             type="button"
//                             className="save-btnnnnnn me-2"
//                           >
//                             <FontAwesomeIcon
//                               icon="fa-solid fa-floppy-disk"
//                               className="me-2"
//                             />
//                             Next
//                           </Button>
//                         </div>
//                       </Row>

//                     </Row>
//                   </Form>
//                 </Container>
//               </Card.Body>
//             </Card>
//           </Offcanvas.Body>
//         </Offcanvas>

//         <ModalSave
//           message={showModal.message}
//           showErrorModal={showModal.code ? true : false}
//         />
//       </div>
//     </>
//   );
// };

// export default AddOffCanvance;

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Product.css";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageValidation } from "../../../utils/common";
import { ValidationImage } from "../../../utils/api";
import ProductAdd from "./ProductAdd/ProductAdd";

const AddOffCanvance = (props) => {
  // const [showModal, setShowModal] = useState({
  //   code: 0,
  //   message: "",
  // });

  // const { postData, getData, Select2Data, getDimension } = useContext(Context);

  // const [categories, setCategories] = useState([]);
  // const [units, setUnits] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  // const [childCategories, setchildCategories] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [taxTypes, setTaxTypes] = useState([]);
  // const [taxPercentages, setPercentages] = useState([]);
  // const [attributes, setAttribute] = useState([]);
  // const [subAttributes, setSubAttribute] = useState([]);

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   getValues,
  //   formState: { errors },
  //   watch,
  //   setValue,
  // } = useForm();
  // const imagesFile = watch("images");
  // const imageFile = watch("image");

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "quantity",
  // });

  // const [quantityError, setquantityError] = useState("");

  // useEffect(() => {
  //   register("quantity", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setquantityError(isValid ? "" : "At least one Quantity is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  // const validateSellingPrice = (value, index) => {
  //   const mrpValue = parseFloat(getValues(`quantity.${index}.mrp`));
  //   const sellingPrice = parseFloat(value);
  //   if (sellingPrice >= mrpValue) {
  //     return "Selling price must be less than MRP";
  //   }
  //   return true; // Validation passed
  // };

  // const onSubmit = async (data) => {
  //   try {
  //     const DataToSend = new FormData();
  //     DataToSend.append("tax_percentage", data?.tax_percentage);
  //     DataToSend.append("category_id", data?.category_id?.value);
  //     DataToSend.append("sub_category_id", data?.sub_category_id?.value);
  //     DataToSend.append("child_category_id", data?.child_category_id?.value);
  //     DataToSend.append("name", data?.name);
  //     DataToSend.append("short_description", data?.short_description);
  //     DataToSend.append("properties", data?.properties);
  //     DataToSend.append("safety_information", data?.safety_information);
  //     DataToSend.append("image", data?.image[0]);

  //     // DataToSend.append("manufacturer", data?.manufacturer);
  //     // DataToSend.append("vender", data?.vender);
  //     // DataToSend.append("storage", data?.storage);
  //     // DataToSend.append("brand_id", data?.brand_id?.value);
  //     // DataToSend.append("tax_type_id", data?.tax_type_id?.value);
  //     // DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);

  //     // Append quantity
  //     const quantity = [];
  //     data.quantity.forEach((variant, index) => {
  //       quantity.push({
  //         quantity: variant.quantity,
  //         unit_id: variant.unit_id.value,
  //         price: variant.price,
  //       });

  //       // let inputImages = document.getElementById(`variantImages${index}`);
  //       // console.log(inputImages.files);
  //       // const files = inputImages.files;
  //       // for (let i = 0; i < files.length; i++) {
  //       //   DataToSend.append(`images${index}`, files[i]);
  //       // }
  //     });

  //     DataToSend.append("quantity", JSON.stringify(quantity));
  //     const response = await postData(`/product`, DataToSend);
  //     console.log("response", response);
  //     console.log("data to send", DataToSend);
  //     if (response?.success) {
  //       await setShowModal({
  //         code: response?.code,
  //         message: response?.message,
  //       });
  //     } else {
  //       await setShowModal({
  //         code: response?.code,
  //         message: response?.message,
  //       });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const GetAllCategory = async () => {
  //   {
  //     const response = await getData("/allcategories");

  //     if (response?.success) {
  //       setCategories(await Select2Data(response?.data, "category_id"));
  //     }
  //   }

  //   {
  //     const response = await getData("/all-units");

  //     if (response?.success) {
  //       setUnits(await Select2Data(response?.data, "unit_id"));
  //     }
  //   }
  // };

  // const GetAllSubCategory = async (id) => {
  //   const response = await getData(`/allsubcategories/${id}`);

  //   if (response?.success) {
  //     setSubCategories(await Select2Data(response?.data, "sub_category_id"));
  //   }
  // };

  // const GetAllChildSubCategory = async (id) => {
  //   const response = await getData(`/allchildcategories/${id}`);

  //   if (response?.success) {
  //     setchildCategories(await Select2Data(response?.data, "sub_category_id"));
  //   }
  // };

  // const GetAllBrands = async () => {
  //   const response = await getData("/allbrands");

  //   if (response?.success) {
  //     setBrands(await Select2Data(response?.data, "brand_id"));
  //   }
  // };

  // const GetAllTaxTypes = async () => {
  //   const response = await getData("/all-tax-type");

  //   if (response?.success) {
  //     setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
  //   }
  // };

  // const GetAllTaxPercentages = async (id) => {
  //   const response = await getData(`/all-tax-percentage/${id}`);

  //   if (response?.success) {
  //     setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
  //   }
  // };

  // const GetAllAttributes = async () => {
  //   const response = await getData(`/all-attribute`);

  //   if (response?.success) {
  //     setAttribute(await Select2Data(response?.data, "attribute_id"));
  //   }
  // };

  // const GetAllSubAttributes = async (id) => {
  //   const response = await getData(`/all-sub-attribute/${id}`);

  //   if (response?.success) {
  //     setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
  //   }
  // };

  // console.log(getValues("images[0]"), "llll");

  // useEffect(() => {
  //   GetAllCategory();
  //   GetAllBrands();
  //   GetAllTaxTypes();
  //   GetAllAttributes();
  // }, []);

  // const deleteImage = async (index) => {
  //   const updatedImagesFile = [...imagesFile];
  //   updatedImagesFile.splice(index, 1);
  //   setValue("images", updatedImagesFile);
  // };

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Add Product</Card.Title>
                <hr />
                <Container>
                  <ProductAdd OffcanvasCloseButton={props.handleClose} />
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>

        {/* <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        /> */}
      </div>
    </>
  );
};

export default AddOffCanvance;
