import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetOrderDetails } from "../../../utils/apis/orders/orders";
library.add(fas);

const EditOffCanvance = (props) => {
  const tabheading = [
    { name: "Sr. No." },
    { name: "Name" },
    { name: "MRP" },
    { name: "Price" },
    { name: "Quantity" },
  ];

  // ----------------------------------------------------------------

  const { IMG_URL } = useContext(Context);
  const [order, setOrder] = useState([]);
  const getData = async () => {
    const res = await GetOrderDetails(props.show);
    setOrder(res.data);
  };

  useEffect(() => {
    if (props.show) {
      getData();
    }
  }, [props.show]);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>View Product</Card.Title>
              <hr />
              <Container>
                <div className="cattable_div">
                  <div className="cattable_main">
                    <Table responsive className="tablees text-center">
                      <thead>
                        <tr>
                          {tabheading.map((heading, index) => (
                            <th
                              className={`headdingss ${
                                index === 0 ? "first-heading" : ""
                              } ${
                                index === tabheading.length - 1
                                  ? "last-heading"
                                  : ""
                              }`}
                              key={index}
                            >
                              {heading.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {order?.orders_details?.map((item, index) => (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td className="bottomtd commmontext">
                              {item.product?.name}
                            </td>
                            <td className="bottomtd commmontext">{item.mrp}</td>
                            <td className="bottomtd commmontext">
                              {item.price}
                            </td>
                            <td className="bottomtd commmontext">
                              {item.quantity}
                            </td>
                          </tr>
                        ))}
                        {/* {tabledata2.map((data, index) => (
                    <tr key={index}>
                      <td>{data.sr_no}</td>
                      <td className="bottomtd commmontext">
                        {data.product_name}
                      </td>
                      <td className="bottomtd commmontext">{data.mrp}</td>
                      <td className="bottomtd commmontext">{data.price}</td>
                      <td className="bottomtd commmontext">{data.quantity}</td>
                    </tr>
                  ))} */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditOffCanvance;
