import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import Successfull_Modal from "../../../common/Successfull_Modal/Successfull_Modal";
import { Button, Nav, Row, Tab, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { CancelButton } from "../../../common/Button";
import { Context } from "../../../../utils/context";
import {
  ProductNarrationChange,
  validateImage,
} from "../../../../utils/common";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import {
  RemoveAll,
  SelectedVariantGet,
  VariantIdDataGet,
  VariantIdDataPost,
} from "../../../../utils/apis/product/Product";

import uploadImage from "./Image/uploadImage.jpg";
import DeleteImage from "./Image/dltt.png";
function Step3({ handleNext, handlePrevious, ...props }) {
  // const [successModal, setSuccessModal] = useState(false);
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   getValues,
  //   formState: { errors },
  //   watch,
  //   setValue,
  // } = useForm();

  // const removeProductImage = async (index) => {
  //   const fileInput = document.getElementById("product_image_" + index);
  //   fileInput.value = "";
  // };

  // const onSubmit = async (data) => {};

  const [redirectToPrev, setRedirectToPrev] = useState(false); // State to control redirection

  const [uploadDefaultImages, setUploadDefaultImages] = useState([
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
  ]);

  const [selectedImage, setSelectedImage] = useState(null);
  const thumbnailChange = async (e) => {
    const file = e.target.files[0];

    setSelectedImage(URL.createObjectURL(file));
  };

  const handleRemoveThumbnail = () => {
    setSelectedImage(null);

    const fileInput = document.getElementById(`thumbnailInput`); // Assuming you have unique IDs for each input
    if (fileInput) {
      fileInput.value = ""; // Clear the value of the input
    }
  };

  const handleProductImage = async (e, index) => {
    const file = e.target.files[0];

    await setValue(`uploadimages[${index}].img`, uploadImage);
    await setValue(`uploadimages[${index}].dltimg`, DeleteImage);
    await setValue(
      `uploadimages[${index}].selectedImage`,
      URL.createObjectURL(file)
    );
    await setValue(`uploadimages[${index}].type`, getMediaType(file.type));
    await setValue(`uploadimages[${index}].status`, true);

    reset(getValues());
  };

  const getMediaType = (fileType) => {
    return fileType.startsWith("image/") ? "image" : "video";
  };

  // const handleProductImage = async (e, index) => {
  //   const selectedFile = e.target.files[0];

  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setValue(`uploadimages[${index}].selectedImage`, reader.result);
  //       // Optionally handle the file read result here if needed
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   }

  //   // console.log(selectedFile);

  //   await setValue(`uploadimages[${index}].img`, uploadImage);
  //   await setValue(
  //     `uploadimages[${index}].type`,
  //     getMediaType(selectedFile.type)
  //   );
  //   await setValue(`uploadimages[${index}].dltimg`, DeleteImage);
  //   // await setValue(`uploadimages[${index}].selectedImage`, selectedFile);
  //   await setValue(`uploadimages[${index}].status`, true);

  //   reset(getValues());
  // };

  const removeProductImage = async (index) => {
    const fileInput = document.getElementById("product_image_" + index);
    fileInput.value = "";

    await setValue(`uploadimages[${index}].img`, uploadImage);
    await setValue(`uploadimages[${index}].dltimg`, DeleteImage);
    await setValue(`uploadimages[${index}].selectedImage`, null);
    await setValue(`uploadimages[${index}].status`, false);
    await setValue(`uploadimages[${index}].path`, null);
    await setValue(`uploadimages[${index}].image_id`, 0);

    reset(getValues());
  };

  // const uploader = Uploader({
  //   apiKey: "free",
  // });

  const options = { multi: true };

  const [allVariantId, setAllVariantId] = useState([]);

  const allIdFunction = async (tableDatas) => {
    // Assuming setAllVariantId and setLevelType are synchronous

    var addId = [];
    var type = "";

    tableDatas?.p_as?.forEach((value, index) => {
      value?.p_a_vs?.forEach((p_a_vs_value, p_a_vs_index) => {
        if (p_a_vs_value?.p_a_v_as?.length > 0) {
          p_a_vs_value?.p_a_v_as?.forEach((p_a_v_as_value, p_a_v_as_index) => {
            p_a_v_as_value?.p_a_v_a_vs?.forEach(
              (p_a_v_a_vs_value, p_a_v_a_vs_index) => {
                // Level 2
                // if (p_a_v_a_vs_value?.status) {
                type = 2;
                addId.push(p_a_v_a_vs_value?.id);
                // }
              }
            );
          });
        } else {
          // Level 1
          // if (p_a_vs_value?.status) {
          type = 1;
          addId.push(p_a_vs_value?.id);
          // }
        }
      });
    });

    // tableDatas?.p_as?.forEach((value, index) => {
    //   value?.p_a_vs?.forEach((p_a_vs_value, p_a_vs_index) => {
    //     p_a_vs_value?.p_a_v_as.length > 0
    //       ? p_a_vs_value?.p_a_v_as?.forEach(
    //           (p_a_v_as_value, p_a_v_as_index) => {
    //             p_a_v_as_value?.p_a_v_a_vs?.forEach(
    //               (p_a_v_a_vs_value, p_a_v_a_vs_index) => {
    //                 // Level 2
    //                 if (p_a_v_a_vs_value?.status) {
    //                   type = 2;
    //                   addId.push(p_a_v_a_vs_value?.id);
    //                 }
    //               }
    //             );
    //           }
    //         )
    //       : (
    //     // Level 1
    //     if (p_a_vs_value?.status) {
    //       type = 2;
    //       addId.push(p_a_vs_value?.id);
    //     }

    //       )
    //   });
    // });

    await setLevelType(type);
    await setAllVariantId(addId);

    await getVariantData(type, addId[0]);
  };

  const [dataStatus, setDataStatus] = useState(false);
  const [allSubmited, setAllSubmited] = useState([]);
  const [variantId, setVariantId] = useState("");
  const [levelType, setLevelType] = useState("");
  const getVariantData = async (type, id) => {
    setDataStatus(false);

    const res = await VariantIdDataGet(type, id);

    if (res?.success) {
      setVariantId(id);
      setLevelType(type);
      if (res?.data?.variant) {
        setValue(`product_id`, res.data.variant.product_id);
        setValue(`stock`, res.data.variant.stock);
        setValue(`mrp`, res.data.variant.mrp);
        setValue(`btb_price`, res.data.variant.btb_price);
        setValue(`btc_price`, res.data.variant.btc_price);
        setValue(`sku_id`, res.data.variant.sku_id);
        setValue(`hsn_code`, res.data.variant.hsn_code);

        //
        setValue(`color`, res.data.variant.color);
        setValue(`width`, res.data.variant.width);
        setValue(`length`, res.data.variant.length);
        setValue(`thickness`, res.data.variant.thickness);
        setValue(`weight`, res.data.variant.weight);

        if (res.data.variant.p_a_v_id) {
          setValue("variant_id", res.data.variant.p_a_v_id);
        }

        if (res.data.variant.p_a_v_a_v_id) {
          setValue("variant_id", res.data.variant.p_a_v_a_v_id);
        }

        const images = [];
        res.data.variant.variant_images.forEach((image, imageIndex) => {
          images.push({
            img: uploadImage,
            dltimg: DeleteImage,
            selectedImage: IMG_URL + image.image,
            path: image.image,
            type: image.type,
            status: true,
            image_id: image.id,
          });
        });
        for (let i = res.data.variant.variant_images.length; i < 8; i++) {
          images.push({
            img: uploadImage,
            dltimg: DeleteImage,
            selectedImage: null,
            type: null,
            status: false,
          });
        }
        setValue(`uploadimages`, images);
        setDataStatus(true);
      } else {
        reset();
        setCurrentTab("1");
        setSelectedImage("");
        // setValue(`description`, "");
        setValue(`uploadimages`, uploadDefaultImages);
      }
      // if (res.data?.id) {
      //   var technical_description = res.data?.s_p_t_descriptions?.map(
      //     (value) => ({
      //       name: value?.name,
      //       description: value?.description,
      //     })
      //   );

      //   if (!technical_description?.length) {
      //     technical_description = [
      //       {
      //         name: "",
      //         description: "",
      //       },
      //     ];
      //   }

      //   reset({
      //     technical_description: technical_description,
      //   });

      //   const images = [];

      //   res.data.s_p_v_a_one_d_a_d_a_d_images.forEach((image, imageIndex) => {
      //     images.push({
      //       img: uploadImage,
      //       dltimg: DeleteImage,
      //       selectedImage: IMG_URL + image.image,
      //       path: image.image,
      //       status: true,
      //       image_id: image.id,
      //     });
      //   });
      //   for (let i = res.data.s_p_v_a_one_d_a_d_a_d_images.length; i < 8; i++) {
      //     images.push({
      //       img: uploadImage,
      //       dltimg: DeleteImage,
      //       selectedImage: null,
      //       status: false,
      //     });
      //   }

      //   setValue(`uploadimages`, images);

      //   setValue(`thumbnail`, res.data.s_p_v_a_o_d_a_d_a_d_o_d.thumbnail);
      //   setValue("description", res.data.s_p_v_a_o_d_a_d_a_d_o_d?.description);
      //   setValue(`discountable`, res.data.s_p_v_a_o_d_a_d_a_d_o_d.discountable);
      //   setValue(
      //     `open_delivery`,
      //     res.data.s_p_v_a_o_d_a_d_a_d_o_d.open_delivery
      //   );

      //   setValue(`breath`, res.data.s_p_v_a_o_d_a_d_a_d_di.breath);
      //   setValue(`height`, res.data.s_p_v_a_o_d_a_d_a_d_di.height);
      //   setValue(`length`, res.data.s_p_v_a_o_d_a_d_a_d_di.length);
      //   setValue(`weight`, res.data.s_p_v_a_o_d_a_d_a_d_di.weight);

      //   setValue(
      //     `after_platform_charges`,
      //     res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.after_platform_charges
      //   );
      //   setValue(
      //     `minimum_order_quantity`,
      //     res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.minimum_order_quantity
      //   );
      //   setValue(`mrp`, res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.mrp);
      //   setValue(
      //     `selling_price`,
      //     res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.selling_price
      //   );
      //   setValue(`stock`, res.data.s_p_v_a_o_d_a_d_a_d_p_a_s_d.stock);

      //   // setValue(`hsn`, res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn);
      //   // setValue(`s_gst_id`, {
      //   //   value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.s_gst.id,
      //   //   label: res.data.s_p_v_a_o_d_a_d_a_d_t_d.s_gst.name,
      //   // });

      //   setValue("hsn_id", {
      //     value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn_id,
      //     label: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name,
      //     value: res.data.s_p_v_a_o_d_a_d_a_d_t_d.hsn_id,
      //     label:
      //       res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name +
      //       " | " +
      //       res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst +
      //       "%" +
      //       " | " +
      //       res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.product_narration,
      //     name: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.name,
      //     percent: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst,
      //     below: res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.below,
      //     gst_narration:
      //       res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst_narration,
      //   });

      //   setHsnNarration(
      //     res.data.s_p_v_a_o_d_a_d_a_d_t_d?.hsn_code?.gst_narration
      //   );

      //   setSelectedImage(IMG_URL + res.data.s_p_v_a_o_d_a_d_a_d_o_d.thumbnail);

      //   setDataStatus(true);
      //   setHsnInputStatus(false);
      //   setCurrentTab("1");
      // } else {

      // reset();
      // setCurrentTab("1");

      // setSelectedImage("");
      // // setValue(`description`, "");
      // setValue(`uploadimages`, uploadDefaultImages);
      // }
    }
  };

  const [tableData, setTableData] = useState([]);
  const getMultipleVariant = async () => {
    if (props.product_id) {
      const res = await SelectedVariantGet(props.product_id);

      if (res?.success) {
        setTableData(res.data);
        allIdFunction(res.data);
      }
    }
  };

  const { getData, IMG_URL } = useContext(Context);
  const [gsts, setGsts] = useState(null);
  const [units, setUnit] = useState([]);
  const [productInfoIcon, setProductInfoIcon] = useState([]);

  const [platform_charges, setPlatform_Charges] = useState("");
  const [hsnCode, setHsnCode] = useState([]);

  const getDataAll = async () => {
    {
      // const res = await GST();
      // if (res?.success) {
      //   setGsts(await Select2Data(res?.data, "s_gst_id", false));
      // }
    }
    {
      // const res = await Unit();
      // if (res?.success) {
      //   setUnit(await Select2Data(res?.data, "unit_id", false));
      // }
    }

    {
      // const res = await PlatformCharges();
      // if (res?.success) {
      //   setPlatform_Charges(res.data.no);
      // }
    }
    {
      // const res = await hsnCodes();
      // if (res?.success) {
      //   setHsnCode(await SelectHsnData(res?.data, "hsn_id", true));
      // }
    }

    {
      // const res = await ProductInfoIcon();
      // if (res?.success) {
      //   setProductInfoIcon(res?.data);
      // }
    }
  };

  // const [hsnInputStatus, setHsnInputStatus] = useState(false);
  // const [existHsn, setExistHsn] = useState("");
  // const [hsnNarration, setHsnNarration] = useState("");
  // const handleHsnChange = async (d) => {
  //   setValue(`hsn_id`, d);
  //   setHsnNarration(d?.gst_narration);
  //   if (d.value === "0") {
  //     setHsnInputStatus(true);
  //     setValue(`hsn_no`, "");
  //     setValue(`gst_percent`, "");
  //     setValue("after_tax_charges", getValues("after_platform_charges"));
  //   } else {
  //     setHsnInputStatus(false);

  //     if (getValues("after_platform_charges")) {
  //       let totalPrice = getValues("after_platform_charges");
  //       let taxvalue = 0;
  //       if (d?.below) {
  //         if (getValues("after_platform_charges") < Number(d?.below)) {
  //           taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
  //         } else {
  //           taxvalue = (12 * getValues("after_platform_charges")) / 100;
  //         }
  //       } else {
  //         taxvalue = (d.percent * getValues("after_platform_charges")) / 100;
  //       }

  //       totalPrice = getValues("after_platform_charges") + taxvalue;

  //       setValue("after_tax_charges", totalPrice);
  //     }
  //   }
  // };

  useEffect(() => {
    getDataAll();
    if (props.product_id) {
      getMultipleVariant();
    }
  }, [props.product_id]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const commonSubmit = async (data, id) => {
    var sendData = new FormData();
    sendData.append("type", levelType);
    sendData.append("variant_id", id);
    sendData.append("product_id", props.product_id);
    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("btb_price", data.btb_price);
    sendData.append("btc_price", data.btc_price);
    sendData.append("sku_id", data.sku_id);
    sendData.append("hsn_code", data.hsn_code);

    //
    sendData.append("color", data.color);
    sendData.append("width", data.width);
    sendData.append("length", data.length);
    sendData.append("thickness", data.thickness);
    sendData.append("weight", data.weight);

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await VariantIdDataPost(sendData);
    return res;
  };
  const onSubmit = async (data) => {
    // return;
    var sendData = new FormData();

    sendData.append("variant_id", variantId);
    sendData.append("type", levelType);
    sendData.append("product_id", props.product_id);
    sendData.append("stock", data.stock);
    sendData.append("mrp", data.mrp);
    sendData.append("btb_price", data.btb_price);
    sendData.append("btc_price", data.btc_price);
    sendData.append("sku_id", data.sku_id);
    sendData.append("hsn_code", data.hsn_code);

    //
    sendData.append("color", data.color);
    sendData.append("width", data.width);
    sendData.append("length", data.length);
    sendData.append("thickness", data.thickness);
    sendData.append("weight", data.weight);

    let count = 0;

    sendData.append("start", count);
    data.uploadimages.forEach((image, imageIndex) => {
      if (image.status) {
        var file = document.getElementById(`product_image_${imageIndex}`);
        if (file.files[0]) {
          sendData.append("image" + count, file.files[0]);
        }
        count++;
      }
    });
    sendData.append("end", count);
    sendData.append("uploadimages", JSON.stringify(data.uploadimages));

    const res = await VariantIdDataPost(sendData);

    if (res?.success) {
      setDataStatus(true);
      const addId = variantId;
      if (!allSubmited.includes(addId)) {
        setAllSubmited([...allSubmited, addId]);
      }
      setError("");
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
      }, 2000);
    }
  };

  const [successModal, setSuccessModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isRemoveChecked, setIsRemoveChecked] = useState(false);
  const handleCommonClick = async () => {
    if (window.confirm("Are you sure you want to make common data for all?")) {
      if (dataStatus) {
        var addId = [];
        for (const value of allVariantId) {
          addId.push(value);
          await commonSubmit(getValues(), value);
        }
        setAllSubmited(addId);
        setIsChecked(true);
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 2000);
      } else {
        alert("Please Select variant which has data");
      }
    } else {
      setIsChecked(false);
    }
  };

  const handleRemoveClick = async () => {
    if (
      window.confirm("Are you sure you want to remove all data of variants?")
    ) {
      setIsRemoveChecked(true);
      for (const value of allVariantId) {
        await RemoveAll(levelType, value);
      }
      setIsRemoveChecked(false);
      setIsChecked(false);
      getMultipleVariant();
      reset();
      setSelectedImage("");
      // setValue(`s_gst_id`, null);
      // // setValue(`s_unit_id`, null);
      // setValue(`description`, "");
      setValue(`uploadimages`, uploadDefaultImages);
      setAllSubmited([]);
    } else {
      setIsRemoveChecked(false);
    }
  };

  console.log(getValues(`uploadimages`));

  const [error, setError] = useState("");
  // const handleNext = async () => {
  //   if (allSubmited.length === allVariantId.length) {
  //     nextStep();
  //   } else {
  //     setError("Please Store All variant data");
  //   }
  // };

  const handlePrev = () => {
    setRedirectToPrev(true); // Set redirectToNext to true
  };

  const [currentTab, setCurrentTab] = useState("1");

  const handleNextTab = async (type) => {
    if (type === "back") {
      if (currentTab != 1) {
        setCurrentTab(Number(currentTab) - 1);
      }
    } else {
      setCurrentTab(Number(currentTab) + 1);
    }
  };

  // -------------------------------------------------------

  // const { append, fields, remove } = useFieldArray({
  //   control,
  //   name: "technical_description",
  // });

  const validateWordCount = (value) => {
    const wordCount = value.length;
    return wordCount <= 40 || "Maximum 40 words are allowed";
  };

  const validateWordCountDescription = (value) => {
    const wordCount = value.length;
    return wordCount <= 500 || "Maximum 500 words are allowed";
  };
  return (
    <>
      <fieldset>
        <section className="single-varient-sec">
          <div className="sec-div ">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-md-12">
                <Table bordered style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="custom-switch"
                          label="(Remove all)"
                          checked={isRemoveChecked}
                          onClick={handleRemoveClick}
                        />
                      </th>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="custom-switch"
                          label="(Common for all)"
                          checked={isChecked}
                          onClick={handleCommonClick}
                        />
                      </th>
                    </tr>
                    <tr>
                      {tableData?.p_as?.map((value, index) => (
                        <>
                          <th>{value?.attribute?.name}</th>
                          {value?.p_a_vs?.map(
                            (p_a_vs_value, p_a_vs_index) =>
                              p_a_vs_index === 0 &&
                              p_a_vs_value?.p_a_v_as?.map(
                                (p_a_v_a_value, p_a_v_a_index) =>
                                  p_a_v_a_index === 0 && (
                                    <th key={`${index}-${p_a_vs_index}`}>
                                      {p_a_v_a_value?.attribute?.name}{" "}
                                    </th>
                                  )
                              )
                          )}
                        </>
                      ))}

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.p_as?.map((value, index) =>
                      value?.p_a_vs?.map((p_a_vs_value, p_a_vs_index) =>
                        p_a_vs_value?.p_a_v_as?.length > 0 ? (
                          p_a_vs_value?.p_a_v_as?.map(
                            (p_a_v_as_value, p_a_v_as_index) =>
                              p_a_v_as_value?.p_a_v_a_vs?.map(
                                (p_a_v_a_vs_value, p_a_v_a_vs_index) => (
                                  <>
                                    <tr>
                                      <td>
                                        {p_a_vs_value?.sub_attribute?.name}{" "}
                                      </td>

                                      <td>
                                        {p_a_v_a_vs_value?.sub_attribute?.name}{" "}
                                      </td>

                                      {p_a_v_a_vs_value?.status ? (
                                        <td
                                          className={
                                            variantId ===
                                              p_a_v_a_vs_value?.id &&
                                            levelType === 2
                                              ? "green-add"
                                              : "red-add"
                                          }
                                          onLoad={() =>
                                            allIdFunction(
                                              2,
                                              p_a_v_a_vs_value?.id
                                            )
                                          }
                                          onClick={() =>
                                            getVariantData(
                                              2,
                                              p_a_v_a_vs_value?.id
                                            )
                                          }
                                        >
                                          Add
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                    </tr>
                                  </>
                                )
                              )
                          )
                        ) : (
                          <>
                            <tr>
                              <td>{p_a_vs_value?.sub_attribute?.name} </td>

                              {p_a_vs_value?.status ? (
                                <td
                                  className={
                                    variantId === p_a_vs_value?.id &&
                                    levelType === 1
                                      ? "green-add"
                                      : "red-add"
                                  }
                                  onLoad={() =>
                                    allIdFunction(1, p_a_vs_value?.id)
                                  }
                                  onClick={() =>
                                    getVariantData(1, p_a_vs_value?.id)
                                  }
                                >
                                  Add
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          </>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="col-xxl-6 col-xl-6 col-md-12">
                <div className="single-variant-tabsshd multi-scroll-box">
                  {variantId && (
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="1"
                      activeKey={currentTab}
                      className="mb-3"
                    >
                      <Row>
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="1"
                              onClick={() => setCurrentTab(1)}
                            >
                              Product Images
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="2"
                              onClick={() => setCurrentTab(2)}
                            >
                              Price & Stock Details
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <div className="tab-content-data">
                              <h6 className="proppdp">
                                Product Photos <span>(Max 8)</span>
                              </h6>
                              <div className="tab-content-data">
                                <div className="uploadbox">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <div className="slider-div">
                                        <Swiper
                                          slidesPerView={3.5}
                                          spaceBetween={30}
                                          freeMode={true}
                                          pagination={{
                                            clickable: false,
                                          }}
                                          modules={[FreeMode, Pagination]}
                                          className="mySwiper"
                                        >
                                          {getValues(`uploadimages`)?.map(
                                            (data, index) => (
                                              <SwiperSlide key={index}>
                                                <div className="upd-card">
                                                  <label
                                                    htmlFor={`product_image_${index}`}
                                                  >
                                                    {data.selectedImage ? (
                                                      data.type == "image" ? (
                                                        <img
                                                          className="slide-img"
                                                          src={
                                                            data.selectedImage
                                                          }
                                                          onClick={() =>
                                                            removeProductImage(
                                                              index
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <object
                                                          className="slide-img"
                                                          data={
                                                            data.selectedImage
                                                          }
                                                          alt="Uploaded media"
                                                          onClick={() =>
                                                            removeProductImage(
                                                              index
                                                            )
                                                          }
                                                        />
                                                      )
                                                    ) : (
                                                      <img
                                                        className="slide-img"
                                                        src={data.img}
                                                      />
                                                    )}
                                                    {/* {data.selectedImage ? (
                                                      <object
                                                        className="slide-img"
                                                        data={
                                                          data.selectedImage
                                                        }
                                                        alt="Uploaded media"
                                                        onClick={() =>
                                                          removeProductImage(
                                                            index
                                                          )
                                                        }
                                                        // width="50"
                                                        // height="50"
                                                      />
                                                    ) : (
                                                      <img
                                                        className="slide-img"
                                                        src={data.img}
                                                        onClick={() =>
                                                          removeProductImage(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    )} */}
                                                  </label>

                                                  <input
                                                    type="file"
                                                    className="d-none"
                                                    accept="image/*,video/*"
                                                    id={`product_image_${index}`}
                                                    onChange={async (e) => {
                                                      await handleProductImage(
                                                        e,
                                                        index
                                                      );
                                                    }}
                                                  />

                                                  {data?.selectedImage && (
                                                    <>
                                                      <img
                                                        className="dltt-slide"
                                                        onClick={() =>
                                                          removeProductImage(
                                                            index
                                                          )
                                                        }
                                                        src={data.dltimg}
                                                        alt="Delete icon"
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </SwiperSlide>
                                            )
                                          )}
                                        </Swiper>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="produuyct-discri">
                                        <h6 className="tttt">
                                          Image Resolution
                                        </h6>
                                        <p className="testtt">
                                          Use clear color images with a ratio of
                                          1:2. and max 500KB
                                        </p>
                                        <h6 className="tttt">
                                          Image Guidelines
                                        </h6>
                                        <p className="testtt">
                                          Upload authentic product photos taken
                                          in bright lighting
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="2">
                            <div className="tab-content-data">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <Form.Label>
                                    Stock
                                    <span className="mandatory-star"> *</span>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-title">
                                          Stock
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-circle-info"
                                        className="ms-3"
                                      />
                                    </OverlayTrigger>
                                  </Form.Label>

                                  <Form.Control
                                    type="number"
                                    name={`stock`}
                                    placeholder="Stock.."
                                    className={classNames("", {
                                      "is-invalid": errors.stock,
                                    })}
                                    {...register(`stock`, {
                                      required: "Stock required",
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> MRP</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`mrp`}
                                    placeholder="MRP.."
                                    className={classNames("", {
                                      "is-invalid": errors.mrp,
                                    })}
                                    {...register(`mrp`, {
                                      required: "MRP required",
                                    })}
                                    onChange={(e) => {
                                      if (
                                        getValues("selling_price") +
                                          Number(platform_charges) >
                                        Number(e.target.value)
                                      ) {
                                        setValue("selling_price", "");
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>B2B Selling Price</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`btb_price`}
                                    placeholder="B2B Selling Price"
                                    className={classNames("", {
                                      "is-invalid": errors.btb_price,
                                    })}
                                    {...register(`btb_price`, {
                                      required: "B2B Selling Price required",
                                    })}
                                    onChange={(e) => {
                                      if (
                                        Number(e.target.value) >
                                        getValues("mrp")
                                      ) {
                                        setValue("btb_price", "");
                                      } else {
                                        setValue("btb_price", e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>B2C Selling Price</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`btc_price`}
                                    placeholder="B2C Selling Price"
                                    className={classNames("", {
                                      "is-invalid": errors.btc_price,
                                    })}
                                    {...register(`btc_price`, {
                                      required: "B2C Selling Price required",
                                    })}
                                    onChange={(e) => {
                                      if (
                                        Number(e.target.value) >
                                        getValues("mrp")
                                      ) {
                                        setValue("btc_price", "");
                                      } else {
                                        setValue("btc_price", e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> SKU ID</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={`sku_id`}
                                    placeholder="SKU ID"
                                    className={classNames("", {
                                      "is-invalid": errors.sku_id,
                                    })}
                                    {...register(`sku_id`, {
                                      required: "SKU ID required",
                                    })}
                                  />
                                </div>{" "}
                                <div className="col-md-4  mb-3">
                                  <Form.Label> HSN Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={`hsn_code`}
                                    placeholder="HSN Code"
                                    className={classNames("", {
                                      "is-invalid": errors.hsn_code,
                                    })}
                                    {...register(`hsn_code`, {
                                      required: "HSN Code required",
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label>
                                    {" "}
                                    Size / Width (inches){" "}
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`width`}
                                    placeholder="Width"
                                    className={classNames("", {
                                      "is-invalid": errors.width,
                                    })}
                                    {...register(`width`, {
                                      required: "Width required",
                                      min: {
                                        value: 0,
                                        message: "Weight must be 0 or more",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> Length (meter)</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`length`}
                                    placeholder="Length"
                                    className={classNames("", {
                                      "is-invalid": errors.length,
                                    })}
                                    {...register(`length`, {
                                      required: "Length required",
                                      min: {
                                        value: 0,
                                        message: "Weight must be 0 or more",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> Thickness (micron)</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`thickness`}
                                    placeholder="Thickness"
                                    className={classNames("", {
                                      "is-invalid": errors.thickness,
                                    })}
                                    {...register(`thickness`, {
                                      required: "Thickness required",
                                      min: {
                                        value: 0,
                                        message: "Weight must be 0 or more",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> Color </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={`color`}
                                    placeholder="Color"
                                    className={classNames("", {
                                      "is-invalid": errors.color,
                                    })}
                                    {...register(`color`, {
                                      required: "Color required",
                                    })}
                                  />
                                </div>
                                <div className="col-md-4  mb-3">
                                  <Form.Label> Weight (kg) </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name={`weight`}
                                    placeholder="Weight"
                                    className={classNames("", {
                                      "is-invalid": errors.weight,
                                    })}
                                    {...register(`weight`, {
                                      required: "Weight required",
                                      min: {
                                        value: 0,
                                        message: "Weight must be 0 or more",
                                      },
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Row>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Button
                            name={"Back"}
                            onClick={() => handleNextTab("back")}
                            type="button"
                            variant="danger"
                            className="  me-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-left"
                              className="me-2"
                            />
                            Back
                          </Button>

                          <Button
                            name={"Save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                            className="save-btnnnnnn me-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                            />
                            save
                          </Button>

                          {currentTab == 2 ? (
                            <></>
                          ) : (
                            <>
                              <Button
                                name={"next"}
                                type="button"
                                className="save-btnnnnnn me-2"
                                onClick={() => handleNextTab("next")}
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-arrow-right"
                                  className="me-2"
                                />
                                Next
                              </Button>
                            </>
                          )}
                        </div>
                      </Row>

                      {error && <sub className="text-danger">{error}</sub>}
                    </Tab.Container>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Row className="mt-5 pb-3">
            <div className="d-flex justify-content-center">
              {/* <Link>
                <CancelButton name={"cancle"} handleClose={props.handleClose} />
              </Link> */}

              <Button
                name={"Back"}
                onClick={handlePrevious}
                type="button"
                variant="danger"
                className="  me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-left"
                  className="me-2"
                />
                Back
              </Button>

              <Button
                name={"Save"}
                onClick={() => {
                  if (allSubmited.length === allVariantId.length) {
                    setSuccessModal(true);
                    setTimeout(() => {
                      setSuccessModal(false);
                      props.OffcanvasCloseButton();
                    }, 2000);
                  } else {
                    setError("Please Store All variant data");
                  }
                }}
                type="button"
                className="save-btnnnnnn me-2"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-floppy-disk"
                  className="me-2"
                />
                Save
              </Button>
            </div>
          </Row>

          <Successfull_Modal
            show={successModal}
            message={"Sucessfully"}
            subMessage={"Details Added Sucessfully"}
            onHide={() => setSuccessModal(false)}
          />
        </section>
      </fieldset>
    </>
  );
}

export default Step3;
